<script setup lang="ts">
import { Button, Popconfirm, type TableColumnType } from 'ant-design-vue'
import PasswordModal from './components/password-modal.vue'
import UserAuthModal from './components/user-auth-modal.vue'
import UserAddModal from './components/user-add-modal.vue'
import AppAuthModal from './components/app-auth-modal.vue'
import { deleteUserApi, queryUsersApi } from '@/api/user'
import { UserTypeMap } from '@/constant/user'

const message = useMessage()
const userStore = useUserStore()
const passwordModelOpen = ref(false)
const userModelOpen = ref(false)
const userAddModelOpen = ref(false)
const appAuthModelOpen = ref(false)
const selectedUser = ref()

const columns = [
  { title: '用户ID', width: 80, dataIndex: 'id' },
  { title: '用户名', dataIndex: 'loginName' },
  { title: '用户类型', dataIndex: 'userType', customRender({ record }) {
    return UserTypeMap.get(record.userType)
  } },
  { title: '设备控制权限', dataIndex: 'authControl', customRender({ record }) {
    return record.authControl ? '有' : '无'
  } },
  { title: '操作', width: 450, customRender({ record }) {
    return h('div', { class: ['flex'] }, [
      h('div', { class: ['flex-1'] }, [h(Button, { type: 'link', size: 'small', onClick() {
        selectedUser.value = record
        passwordModelOpen.value = true
      } }, () => '修改密码')]),
      h('div', { class: ['flex-1'] }, [
        h(Button, { type: 'link', size: 'small', onClick() {
          selectedUser.value = record
          appAuthModelOpen.value = true
        } }, () => '应用权限'),
      ]),
      h('div', { class: ['flex-1'] }, [record.userType !== 'DEPLOYER'
        ? h(Button, { type: 'link', size: 'small', onClick() {
          selectedUser.value = record
          userModelOpen.value = true
        } }, () => '权限')
        : '']),
      h('div', { class: ['flex-1'] }, [(record.loginName !== 'deployer' && record.loginName !== 'manager')
        ? h(Popconfirm, { title: '确定删除？', onConfirm: () => onDelete(record) }, () => h(Button, { type: 'link', size: 'small', danger: true }, () => '删除'))
        : '']),
    ])
  } },
] as TableColumnType[]

const search = ref<TableSearchType[]>([
  { label: '用户名', name: 'loginName', placeholder: '请输入用户名', required: false, type: 'input' },
  { label: '设备控制权', name: 'authControl', placeholder: '请选择', required: false, type: 'select', options: [
    { label: '有权限', value: true },
    { label: '无权限', value: false },
  ] },
  { label: '用户类型', name: 'userType', placeholder: '请选择', required: false, type: 'select', options: [
    { label: '经营管理员', value: 'MANAGER' },
    { label: '普通用户', value: 'RUNTIME' },
  ] },
])

onMounted(initSearchOption)

function initSearchOption() {
  const searchAim = search.value.find(item => item.name === 'userType')
  if (searchAim && userStore.isRole('DEPLOYER')) {
    searchAim.options?.unshift({ label: '实施工程师', value: 'DEPLOYER' })
  }
}

const { state, onPageChange, onSearch, refresh } = usePageFetch({ apiFun: queryUsersApi, columns, needPaging: true })

async function onDelete(record: any) {
  await deleteUserApi(record.id)
  message.success('删除成功')
  refresh()
}

function onAddUser() {
  userAddModelOpen.value = true
}
</script>

<template>
  <page-container>
    <content-header title="用户管理" />

    <flex-content>
      <div h-full overflow-hidden flex flex-col>
        <div rounded p-12px mb-8px style="background: var(--bg-color)">
          <search-form :search="search" @search="onSearch" />
        </div>
        <table-layout>
          <template #headerLeft>
            <a-space>
              <a-button type="primary" @click="onAddUser">
                新增用户
              </a-button>
            </a-space>
          </template>
          <template #content>
            <base-table v-bind="state" @page-change="onPageChange" />
          </template>
        </table-layout>
      </div>
    </flex-content>
    <PasswordModal :id="selectedUser?.id" v-model:open="passwordModelOpen" />
    <UserAuthModal v-model:open="userModelOpen" :user="selectedUser" @confirm="refresh" />
    <UserAddModal v-model:open="userAddModelOpen" @confirm="refresh" />
    <AppAuthModal v-model:open="appAuthModelOpen" :users="selectedUser" @confirm="refresh" />
  </page-container>
</template>
