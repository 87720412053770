<script setup lang="ts">
import { cloneDeep, concat, filter, flatMap, forEach, includes, map, pick, reduce } from 'lodash-es'
import { updateUserApi } from '@/api/user'

const props = defineProps(['user'])
const emit = defineEmits(['confirm'])
const configStore = useWorkBenchCacheConfigStore()
const { apps, categories } = storeToRefs(configStore)
const open = defineModel<boolean>('open')
const message = useMessage()
const userFormRef = ref()
const nextUser = ref<any>({ userType: '', authControl: false, workbenchConfig: null })
const checkedApps = ref<number[]>([])

watch(open, (newOpen) => {
  if (newOpen === true) {
    nextUser.value = cloneDeep(props.user)
    if (nextUser.value != null && nextUser.value.workbenchConfig != null) {
      initCheckApps()
    }
  }
})

function initCheckApps() {
  if (props.user) {
    const userCategories = nextUser.value.workbenchConfig?.categories || []
    checkedApps.value = flatMap(userCategories, 'apps')
  }
}

function onSubmit() {
  if (checkedApps.value.length !== 0) {
    nextUser.value.workbenchConfig = JSON.stringify(getUserWorkBrench()) as any
  }
  else {
    nextUser.value.workbenchConfig = null
  }

  userFormRef.value.validate().then(async () => {
    nextUser.value.password = 'ss'
    const isSuccess = await updateUserApi(nextUser.value)
    if (isSuccess) {
      message.success('修改成功')
      open.value = false
      userFormRef.value?.resetFields()
      checkedApps.value = []
      emit('confirm')
    }
  })
}

function getUserWorkBrench() {
  const nextCategories = getUserWorkBenchConfig(categories.value)

  return { categories: getNotEmptyCategories(nextCategories) }

  function getUserWorkBenchConfig(categories: any[]) {
    const nextCategories: any[] = []

    forEach(categories, (category) => {
      nextCategories.push({
        id: category.id,
        apps: reduce(category.apps, (result: any[], app: any) => (isAppChecked(checkedApps.value, app.id) ? concat(result, app.id) : result), []),
      })
    })

    return nextCategories
  }

  function isAppChecked(checkedApps: any, appId: number) {
    return includes(checkedApps, appId)
  }

  function getNotEmptyCategories(categories: any[]) {
    return filter(categories, category => (category.apps && category.apps.length > 0))
  }
}

function onCancel() {
  userFormRef.value?.resetFields()
  checkedApps.value = []
  open.value = false
}
</script>

<template>
  <a-modal v-model:open="open" title="权限" width="50%" :centered="true" @ok="onSubmit" @cancel="onCancel">
    <a-form ref="userFormRef" :model="nextUser" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="用户类型" name="userType" required>
        <a-select v-model:value="nextUser.userType">
          <a-select-option v-if="nextUser.userType === 'DEPLOYER'" value="DEPLOYER">
            实施工程师
          </a-select-option>
          <a-select-option value="MANAGER">
            经营管理员
          </a-select-option>
          <a-select-option value="RUNTIME">
            普通用户
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-if="nextUser.userType === 'RUNTIME'" label="设备控制权限" name="authControl" required>
        <a-radio-group v-model:value="nextUser.authControl" name="authControl">
          <a-radio :value="true">
            有
          </a-radio>
          <a-radio :value="false">
            无
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="应用配置" name="workbenchConfig">
        <a-checkbox-group v-model:value="checkedApps" size="small">
          <a-checkbox v-for="app in apps" :key="app.id" :value="app.id">
            {{ app.name }}
          </a-checkbox>
        </a-checkbox-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
