<script setup lang="ts">
import { Empty } from 'ant-design-vue'

const props = defineProps(['users'])

const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
const workBenchCacheConfigStore = useWorkBenchCacheConfigStore()
const { apps } = storeToRefs(workBenchCacheConfigStore)

const userStore = useUserStore()
const { user: storeUser } = storeToRefs(userStore)
const open = defineModel<boolean>('open')
const iframeDom = ref()
const loading = ref(true)
const authUrl = ref('')
const selectedKeys = ref<any[]>([])

watch(open, (newOpen) => {
  if (newOpen === true) {
    initChoose()
  }
}, { immediate: true })

function finishLoading() {
  if (iframeDom.value) {
    const iframeWindow = iframeDom.value.contentWindow
    const message = { user: storeUser.value, parentOrigin: window.location.origin }
    // 参数: 1.要发送的数据, 2.目标域名
    iframeWindow.postMessage(message, '*')
  }

  loading.value = false
}

const hasAuthUrl = (app: any) => app.authUrl && app.authUrl !== ''
const filterAuthApp = (apps: any[]) => apps.filter(hasAuthUrl)
const hasWorkBenchConfig = (user: any) => user.workbenchConfig != null
const pickAppIds = (categories: any[]) => categories.reduce((pre, cur) => [...pre, ...cur.apps], [])
const filterUserApps = (allApps: any[], userAppIds: string[]) => allApps.filter(app => userAppIds.includes(app.id))
const isDeployer = (user: any) => user.userType === 'DEPLOYER'

const authApps = computed(() => {
  if (!props.users)
    return []
  if (isDeployer(props.users)) {
    return filterAuthApp(apps.value)
  }
  else {
    if (hasWorkBenchConfig(props.users)) {
      const categories = props.users.workbenchConfig.categories
      const userApps = filterUserApps(apps.value, pickAppIds(categories))
      const authApps = filterAuthApp(userApps)
      return authApps
    }
    return []
  }
})

function onSelectApp(item: any) {
  const { key, selectedKeys } = item
  selectedKeys.value = selectedKeys
  const app = authApps.value.find((item: any) => item.id === key)
  authUrl.value = app.authUrl
  loading.value = true
}

function initChoose() {
  if (authApps.value.length > 0) {
    const firstApp = authApps.value[0]
    selectedKeys.value = [firstApp.id]
    authUrl.value = firstApp.authUrl
    loading.value = true
  }
}

function onCancel() {
  authUrl.value = ''
}
</script>

<template>
  <a-modal v-model:open="open" title="应用权限" width="80%" :centered="true" :footer="null" @cancel="onCancel">
    <div h-800px flex style="border:1px solid rgb(230, 230, 230)">
      <div w-200px flex-none style="border-right:1px solid rgb(230, 230, 230)">
        <div p-10px text-14px style="border-bottom:1px solid rgb(230, 230, 230)">
          应用名称
        </div>
        <a-empty v-if="authApps.length === 0" :image="simpleImage" />
        <a-menu v-else v-model:selected-keys="selectedKeys" @select="onSelectApp">
          <a-menu-item v-for="app in authApps" :key="app.id">
            <template #icon>
              <img :src="app.icon" style="width: 20px;height: 20px;vertical-align: sub;">
            </template>
            <template #default>
              {{ app.name }}
            </template>
          </a-menu-item>
        </a-menu>
      </div>
      <div flex-1 flex-col overflow-hidden>
        <div p-10px text-14px style="border-bottom:1px solid rgb(230, 230, 230)">
          权限配置
        </div>
        <div class="bg-[var(--bg-color)] ant-pro-iframe-wrap" w-full h-full of-hidden flex flex-col flex-1>
          <a-spin :spinning="loading" wrapper-class-name="of-hidden w-full h-full flex flex-col flex-1">
            <a-empty v-if="loading === false && !authUrl" :image="simpleImage" />
            <iframe
              v-else
              ref="iframeDom" w-full h-full flex flex-col flex-1 :src="authUrl" style="border: none"
              @load="finishLoading"
            />
          </a-spin>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<style scoped lang="scss">
:deep(.ant-pro-iframe-wrap) {
    .ant-spin-container {
        height: 100% !important;
        width: 100% !important;
        display: flex;
        flex-direction: column;
        flex: 1;
    }
}
</style>
