<script setup lang="ts">
import { updatePasswordApi } from '@/api/user'

const props = defineProps(['id'])
const open = defineModel<boolean>('open')
const message = useMessage()
const passwordFormState = ref<any>({ password: '' })
const passwordFormRef = ref()

function onSubmit() {
  passwordFormRef.value.validate().then(async () => {
    passwordFormState.value.userId = props.id
    const isSuccess = await updatePasswordApi(passwordFormState.value)
    if (isSuccess) {
      message.success('修改成功')
      open.value = false
      passwordFormRef.value?.resetFields()
    }
  })
}
</script>

<template>
  <a-modal v-model:open="open" title="修改密码" width="50%" :centered="true" @ok="onSubmit">
    <a-form ref="passwordFormRef" :model="passwordFormState" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="密码" name="password" required>
        <a-input v-model:value="passwordFormState.password" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
