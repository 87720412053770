<script setup lang="ts">
import { addUserApi } from '@/api/user'

const emit = defineEmits(['confirm'])
const userStore = useUserStore()

const open = defineModel<boolean>('open')
const message = useMessage()
const userFormRef = ref()
const nextUser = ref({ loginName: '', password: '', userType: '', authControl: true })

function onSubmit() {
  userFormRef.value.validate().then(async () => {
    const isSuccess = await addUserApi(nextUser.value as any)
    if (isSuccess) {
      message.success('修改成功')
      open.value = false
      userFormRef.value?.resetFields()
      emit('confirm')
    }
  })
}
</script>

<template>
  <a-modal v-model:open="open" title="新增用户" width="50%" :centered="true" @ok="onSubmit">
    <a-form ref="userFormRef" :model="nextUser" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="用户名" name="loginName" required>
        <a-input v-model:value="nextUser.loginName" />
      </a-form-item>
      <a-form-item label="用户密码" name="password" required>
        <a-input v-model:value="nextUser.password" />
      </a-form-item>
      <a-form-item label="用户类型" name="userType" required>
        <a-select v-model:value="nextUser.userType">
          <a-select-option v-if="userStore.isRole('DEPLOYER')" value="DEPLOYER">
            实施工程师
          </a-select-option>
          <a-select-option value="MANAGER">
            经营管理员
          </a-select-option>
          <a-select-option value="RUNTIME">
            普通用户
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-if="nextUser.userType === 'RUNTIME'" label="设备控制权限" name="authControl" required>
        <a-radio-group v-model:value="nextUser.authControl" name="authControl">
          <a-radio :value="true">
            有
          </a-radio>
          <a-radio :value="false">
            无
          </a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
